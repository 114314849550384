// Imports => React
import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcImage from '@atoms/ac-image/ac-image.web';
import AcNectarSlider from '@components/ac-nectar-slider/ac-nectar-slider.web';

const _CLASSES = {
  MAIN: 'ac-project-description',
  IMAGE: {
    MAIN: 'ac-project-description__image',
    WRP: 'ac-project-description__image-wrp',
  },
};

const AcProjectDescription = ({ project, section }) => {
  const getImageClassNames = useCallback(() => {
    return clsx(_CLASSES.IMAGE.MAIN);
  }, []);

  const getImageWrpClassNames = useCallback(() => {
    return clsx(_CLASSES.IMAGE.WRP);
  }, []);

  let image = project.project_description.image;
  if (!image || !image.responsive || !image.responsive.lg) {
    if (
      project.cover &&
      project.cover.responsive &&
      project.cover.responsive.lg
    ) {
      image = project.cover;
    } else {
      image = false;
    }
  }

  let featured_images = null;
  if (project && project.project_featured_images) {
    featured_images = project.project_featured_images;
  }

  const renderCoverImage = useMemo(() => {
    if (featured_images && featured_images.length > 0) {
      return <AcNectarSlider slides={featured_images} />;
    } else if (image && image.responsive && image.responsive.lg) {
      return (
        <AcImage
          source={image.responsive.lg}
          className={getImageClassNames()}
          wrpClassName={getImageWrpClassNames()}
          parallax={true}
        />
      );
    }
  }, [image, featured_images]);

  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn xs={12}>
            <AcHeading rank={4}>{section.label}</AcHeading>
          </AcColumn>
        </AcRow>

        {project.project_description && (
          <>
            {renderCoverImage}

            <AcRow className={'h-margin-top-35 h-text--align-left'}>
              <AcColumn xs={12} sm={12} md={3} lg={2}>
                <AcHeading
                  rank={6}
                  theme={'alpha'}
                  variant={'uppercase'}
                  className={'h-text--align-left h-margin-y-10'}
                >
                  {project.project_description.title}
                </AcHeading>
                {project.city && (
                  <AcHeading
                    rank={6}
                    theme={'alpha'}
                    variant={'uppercase'}
                    className={'h-text--align-left h-margin-y-5'}
                  >
                    {project.city}
                  </AcHeading>
                )}
                {project.region === 'USA' && project.state && (
                  <AcHeading
                    rank={6}
                    theme={'alpha'}
                    variant={'uppercase'}
                    className={'h-text--align-left h-margin-y-5'}
                  >
                    {project.state}
                  </AcHeading>
                )}
                {project.country && project.country.name && (
                  <AcHeading
                    rank={6}
                    theme={'alpha'}
                    variant={'uppercase'}
                    className={'h-text--align-left h-margin-y-5'}
                  >
                    {project.country.name}
                  </AcHeading>
                )}
              </AcColumn>
              {project.project_description &&
                project.project_description.description && (
                  <AcColumn xs={12} sm={12} md={{ size: 9, offset: 1 }}>
                    <AcHeading
                      rank={5}
                      variant={'uppercase'}
                      className={'h-text--align-left h-margin-bottom-10'}
                    >
                      {project.project_description.description}
                    </AcHeading>
                  </AcColumn>
                )}
            </AcRow>

            <AcRow>
              <AcColumn xs={12}>
                <AcDivider theme={'alpha'} className={'h-margin-y-25'} />
              </AcColumn>
            </AcRow>

            {project.project_description && project.project_description.body && (
              <>
                <AcRow>
                  <AcColumn xs={12} sm={12} md={{ size: 9, offset: 3 }}>
                    <AcRichContent
                      content={project.project_description.body}
                      className={'h-text--align-left'}
                    />
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn xs={12}>
                    <AcDivider
                      theme={'alpha'}
                      className={'h-margin-top-25 h-margin-bottom-0'}
                    />
                  </AcColumn>
                </AcRow>
              </>
            )}
          </>
        )}

        {(!project.project_description ||
          project.project_description.length === 0) && (
          <AcRow>
            <AcColumn xs={12}>
              <AcRichContent
                content={'<p>Geen project beschrijving beschikbaar</p>'}
                className={'h-text--color-medium h-text--italic'}
              />
            </AcColumn>
          </AcRow>
        )}
      </AcContainer>
    </AcSection>
  );
};

export default React.memo(AcProjectDescription);
