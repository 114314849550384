import React from 'react';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcSwiper from '@components/ac-swiper/ac-swiper.js';

const GetTypeOfVideoURL = url => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(https?\/\/)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );
  var type = null;
  if (url.indexOf('youtu') > -1) {
    type = 'youtube';
  } else if (url.indexOf('vimeo') > -1) {
    type = 'vimeo';
  }

  return type;
};

const AcProjectCarousel = ({ project, section }) => {
  const { updates } = project;

  // Sort Project Updates by 'published_at' date and filter out 'draft' updates
  const sortedUpdates = updates
    .filter(a => {
      return a.status !== 'draft';
    })
    .sort((a, b) => {
      const keyA = a.published_at;
      const keyB = b.published_at;

      const arrA = keyA.split('-');
      const arrB = keyB.split('-');

      const dA = `${arrA[1]}-${arrA[0]}-${arrA[2]}`;
      const dB = `${arrB[1]}-${arrB[0]}-${arrB[2]}`;

      const dateA = new Date(dA);
      const dateB = new Date(dB);

      // Compare the 2 dates
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      return 0;
    });

  // grab the Project Update media per Project Update
  let updateMedia = [];
  sortedUpdates.forEach(n => {
    if (n.attachments) {
      const { images, videos } = n.attachments;

      if (images) {
        const img = images.map(im => {
          let published_at = n.published_at;

          if (typeof published_at !== 'undefined' && published_at !== null) {
            published_at = published_at.split('-');
            published_at = `${published_at[1]}-${published_at[0]}-${published_at[2]}`;
            published_at = new Date(published_at);
          }

          const nImg = {
            ...im,
            update: { title: n.name, published: n.published_at },
            published_at,
            _type: 'image',
            type: 'image',
          };

          return nImg;
        });

        updateMedia = [...updateMedia, ...img];
      }

      if (videos)
        updateMedia = [
          ...updateMedia,
          ...videos
            .filter(({ position, url }) => {
              const type = GetTypeOfVideoURL(url);

              if (type !== 'vimeo' && type !== 'youtube') return false;
              if (position !== 'sidebar') return true;
              return false;
            })
            .map(v => ({ ...v, _type: 'video', type: 'video' })),
        ];
    }
  });

  // Sort the Project Images by id (uploaded order)
  const projectImages = project.images.map(b => ({
    ...b,
    _type: 'image',
    type: 'image',
  }));

  let media = [
    ...projectImages,
    ...project.project_videos
      .filter(({ position, url }) => {
        const type = GetTypeOfVideoURL(url);

        if (type !== 'vimeo' && type !== 'youtube') return false;
        if (position !== 'sidebar') return true;
        return false;
      })
      .map(b => ({
        ...b,
        _type: 'video',
        type: 'video',
      })),
    ...updateMedia,
  ].sort((a, b) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    return aDate - bDate;
  });

  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcHeading rank={4}>{section.label}</AcHeading>
      <AcSwiper items={media} stacked />
    </AcSection>
  );
};

export default AcProjectCarousel;
