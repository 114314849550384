// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-toaster-hoc',
};

class AcToasterHocController extends React.Component {
  constructor(props) {
    super(props);

    this.init = this.init.bind(this);

    this.store = props.store;
    this.routing = props.routing;

    this.state = {};
  }

  componentDidMount() {
    // this.store.toasters.updateQueue();
    this.init();
  }

  init() {
    const UP = 38,
      DOWN = 40,
      LEFT = 37,
      RIGHT = 39,
      ENTER = 13,
      B = 66,
      A = 65;

    const seq = [UP, UP, DOWN, DOWN, LEFT, RIGHT, LEFT, RIGHT, B, A, ENTER];

    let keyIndex = 0;
    let timeout;

    const konami = event => {
      const code = event.keyCode;

      if (seq[keyIndex] === code) {
        keyIndex++;
        if (keyIndex === seq.length) {
          this.store.toasters.updateQueue();
          return;
        }
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          keyIndex = 0;
        }, 500);
      } else {
        keyIndex = 0;
      }
    };

    window.addEventListener('keyup', konami);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcToasterHocController.propTypes = {};

AcToasterHocController.defaultProps = {};

export default AcToasterHocController;
