import AcParticipationStepBankAccount from './ac-participation-step-bankaccount/ac-participation-step-bankaccount.web.js';
import AcParticipationStepCompany from './ac-participation-step-company/ac-participation-step-company.web.js';
import AcParticipationStepConfirmation from './ac-participation-step-confirmation/ac-participation-step-confirmation.web.js';
import AcParticipationStepInvestment from './ac-participation-step-investment/ac-participation-step-investment.web.js';
import AcParticipationStepPersonal from './ac-participation-step-personal/ac-participation-step-personal.web.js';
import AcParticipationStepSignature from './ac-participation-step-signature/ac-participation-step-signature.web.js';

export {
  AcParticipationStepBankAccount,
  AcParticipationStepCompany,
  AcParticipationStepConfirmation,
  AcParticipationStepInvestment,
  AcParticipationStepPersonal,
  AcParticipationStepSignature,
};
