// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Imports => Controller
import AcToasterHocController from './ac-toaster-hoc.controller.js';

// Imports => Controller
import AcToaster from '@atoms/ac-toaster/ac-toaster.web.js';

@inject('routing', 'store')
@observer
class AcToasterHoc extends AcToasterHocController {
  render() {
    const { queue: Toasters } = this.store.toasters;

    return (
      <TransitionGroup className={this.getStyleClassNames()}>
        {Toasters &&
          Toasters.map(toast => (
            <CSSTransition
              classNames={'ac-toaster-transition'}
              timeout={{ enter: 1000, exit: 1000 }}
              key={`ac-toast-${toast.id}`}
            >
              <AcToaster {...toast} />
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  }
}

export default AcToasterHoc;
