// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

import AcDocument from '@atoms/ac-document/ac-document.web';

const _CLASSES = {
  MAIN: 'ac-project-documents',
  CONTENT: 'ac-project-documents__content',
};

const AcProjectDocuments = ({ project, section, size = 180 }) => {
  const getContentClassNames = useCallback(() => {
    return clsx(_CLASSES.CONTENT);
  }, []);

  const buildDocuments = useCallback((documents) => {
    return documents.map((document, index) => (
      <AcDocument
        {...document}
        size={size}
        mimetype={document.mime_type}
        key={`ac-project-document-${document.id}`}
      />
    ));
  }, []);

  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            <AcHeading rank={4}>{section.label}</AcHeading>
          </AcColumn>
        </AcRow>

        {project.documents && project.documents.length > 0 && (
          <div className={getContentClassNames()}>
            {buildDocuments(project.documents)}
          </div>
        )}

        {(!project.documents || project.documents.length === 0) && (
          <AcRichContent
            content={'<p>Geen documenten beschikbaar</p>'}
            className={'h-text--color-medium h-text--italic'}
          />
        )}
      </AcContainer>
    </AcSection>
  );
};

export default React.memo(AcProjectDocuments);
