// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';

import AcSection from '@atoms/ac-section/ac-section.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

import AcContact from '@molecules/ac-contact/ac-contact.web';

const _CLASSES = {
  MAIN: 'ac-project-contacts',
  CONTAINER: 'ac-project-contacts__container',
};

const AcProjectContacts = ({ project, section }) => {
  const getContactsContainerStyleClassNames = useCallback(() => {
    return clsx(_CLASSES.CONTAINER);
  }, []);

  const buildContacts = useCallback(contact => {
    return (
      <AcContact
        key={`ac-project-contact-${contact.id}`}
        {...contact}
        width={220}
      />
    );
  }, []);

  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            <AcHeading rank={4} theme={'white'}>
              {section.label}
            </AcHeading>
          </AcColumn>
        </AcRow>
        {project.contact && (
          <div className={getContactsContainerStyleClassNames()}>
            {buildContacts(project.contact)}
          </div>
        )}
      </AcContainer>
    </AcSection>
  );
};

export default React.memo(AcProjectContacts);
