// Imports => React
import React from 'react';

// Imports => Controller
import AcProjectApplicationController from './ac-project-application.controller.js';

// Imports => Components
import AcStepper from '@components/ac-stepper/ac-stepper.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';

import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';

class AcProjectApplication extends AcProjectApplicationController {
  render() {
    const { project, section } = this.props;
    return (
      <AcSection
        theme={section.theme}
        key={section.id}
        id={`${section.rel}-${section.id}`}
      >
        <AcContainer fluid={false}>
          <AcRow>
            <AcColumn>
              <AcHeading rank={4}>{section.label}</AcHeading>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn>
              {project.application && (
                <>
                  <AcStepper
                    steps={project.application}
                    interactive={false}
                    indexes={true}
                    size={'default'}
                    titlePlacement={'bottom'}
                  />
                </>
              )}

              {(!project.application || project.application.length === 0) && (
                <AcRichContent
                  content={
                    '<p>Geen voortgang van uw inschrijving beschikbaar</p>'
                  }
                  className={'h-text--color-medium h-text--italic'}
                />
              )}
            </AcColumn>
          </AcRow>
        </AcContainer>
      </AcSection>
    );
  }
}

export default AcProjectApplication;
