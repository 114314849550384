// Imports => React
import React, { useEffect, useState, useRef, useCallback, useMemo, memo } from 'react';
import queryString from 'query-string';

// Imports => Molecules
import AcProjectUpdate from '@molecules/ac-project-update/ac-project-update.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';

import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcButton from '@atoms/ac-button/ac-button.web.js';

const AcProjectUpdates = memo(({ project, section }) => {
  const { update, draft } = queryString.parse(window.location.search);

  let openUpdateById = false;

  if (update || draft) {
    openUpdateById = parseInt(update || draft, 10);
  }

  const scrollTo = ref =>
    ref &&
    ref.current &&
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  const anchorRef = useRef(null);

  const anchorScroll = () => scrollTo(anchorRef);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        anchorScroll();
      }, 1000);
    }
  }, []);

  const handleShowAllUpdatesToggle = initialValue => {
    const [value, setValue] = useState(initialValue);
    const toggle = useCallback(() => {
      setValue(v => !v);
    }, []);
    return [value, toggle];
  };

  const [showAllUpdates, toggleShowAllUpdates] = handleShowAllUpdatesToggle(
    openUpdateById ? true : false
  );

  const renderUpdates = useMemo(() => {
    if (!project.some_updates) return null;
    if (!project.some_updates.length) return null;

    const collection = project.some_updates.slice(0, !showAllUpdates ? 3 : undefined);

    let x = 0;
    let len = collection.length;
    let result = [];

    for (x; x < len; x++) {
      const update = collection[x];
      const shouldBeOpenById = openUpdateById && openUpdateById === update.id;

      const obj = <div
                  key={`ac-project-update-${update.id}`}
                  ref={shouldBeOpenById ? anchorRef : undefined}
                >
                  <AcProjectUpdate
                    {...update}
                    projectId={update.id}
                    id={`#${update.title}`}
                    expanded={shouldBeOpenById}
                  />
                </div>;

      result.push(obj);
    }

    return result;
  }, [project, project.some_updates, openUpdateById, showAllUpdates]);

  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            <AcHeading rank={4}>{section.label}</AcHeading>
          </AcColumn>
        </AcRow>
        
        {renderUpdates}

        {project.some_updates && project.some_updates.length >= 3 && (
          <AcButton theme={'alpha'} callback={toggleShowAllUpdates}>
            {!showAllUpdates ? 'Toon alle updates' : 'Toon minder updates'}
          </AcButton>
        )}
        {(!project.some_updates || project.some_updates.length === 0) && (
          <AcRichContent
            content={'<p>Geen updates beschikbaar</p>'}
            className={'h-text--color-medium h-text--italic'}
          />
        )}
      </AcContainer>
    </AcSection>
  );
});

export default AcProjectUpdates;
