// Imports => React
import React from 'react';

// Imports => Atoms
import {AcContainer, AcRow, AcColumn} from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

import AcAttributes from '@atoms/ac-attributes/ac-attributes.web';

// Imports => Utils
import {uuidv4} from '@utils/ac-generate-id.js';

const AcProjectKeyFigures = ({project, section}) => {
    const keyFiguresArray = [
        project &&
        project.url_google_maps !== null &&
        project.show_google_maps !== undefined
            ? {
                label: 'Locatie',
                locations: [
                    project.location != null ? project.location :
                        (project.country.name && project.city
                            ? `${project.country.name}, ${project.city}`
                            : project.country.name),
                    project.extra_location1,
                    project.extra_location2,
                    project.extra_location3,
                    project.extra_location4,
                    project.extra_location5,
                ],
                values: [
                    project.url_google_maps,
                    project.extra_location1_maps,
                    project.extra_location2_maps,
                    project.extra_location3_maps,
                    project.extra_location4_maps,
                    project.extra_location5_maps,
                ],
                shows: [
                    project.show_google_maps,
                    project.extra_location1_maps_show,
                    project.extra_location2_maps_show,
                    project.extra_location3_maps_show,
                    project.extra_location4_maps_show,
                    project.extra_location5_maps_show,
                ],
                id: uuidv4,
            }
            : {},
        ...project.key_figures,
    ];

    return (
        <AcSection
            theme={section.theme}
            key={section.id}
            id={`${section.rel}-${section.id}`}
        >
            <AcContainer fluid={false}>
                <AcRow>
                    <AcColumn xs={12} md={{size: 10, offset: 1}}>
                        <AcHeading rank={4}>{section.label}</AcHeading>
                    </AcColumn>
                </AcRow>

                <AcRow className="ac-key-figures--collection">
                    <AcColumn>
                        {keyFiguresArray && keyFiguresArray.length && (
                            <AcAttributes attributes={keyFiguresArray}/>
                        )}

                        {(!keyFiguresArray || keyFiguresArray.length === 0) && (
                            <AcRichContent
                                content={'<p>Geen kerngegevens beschikbaar</p>'}
                                className={'h-text--color-medium h-text--italic'}
                            />
                        )}
                    </AcColumn>
                </AcRow>
            </AcContainer>
        </AcSection>
    );
};

export default React.memo(AcProjectKeyFigures);
