// Imports => React
import React from 'react';

// Imports => Components
import AcStepper from '@components/ac-stepper/ac-stepper.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const AcProjectStatus = ({ project, section }) => {
  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            <AcHeading rank={4}>{section.label}</AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            {project.status_updates && (
              <>
                <AcStepper
                  steps={project.status_updates}
                  interactive={false}
                  indexes={false}
                  size={'small'}
                />
              </>
            )}

            {(!project.status_updates ||
              project.status_updates.length === 0) && (
              <AcRichContent
                content={'<p>Geen status beschikbaar</p>'}
                className={'h-text--color-medium h-text--italic'}
              />
            )}
          </AcColumn>
        </AcRow>
      </AcContainer>
    </AcSection>
  );
};

export default React.memo(AcProjectStatus);
