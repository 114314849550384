// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-project-application',
};

class AcProjectApplicationController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcProjectApplicationController.propTypes = {};

AcProjectApplicationController.defaultProps = {};

export default AcProjectApplicationController;
