// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-participation-step-personal',
  FORM: {
    MAIN: 'ac-participation__form',
    BLOCK: 'ac-participation__form--block',
  },
};

class AcParticipationStepPersonalController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {};

    this.handleBackBtn = this.handleBackBtn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleBackBtn(event) {
    if (event) event.preventDefault();

    const { step, backbtn } = this.props;

    if (backbtn) backbtn(event, step);
  }

  handleSubmit(event) {
    if (event) event.preventDefault();

    const { step, callback } = this.props;

    if (callback) callback(event, step);
  }

  getFormClassNames() {
    const { loading } = this.store.participation;
    return clsx(_CLASSES.FORM.MAIN, loading && _CLASSES.FORM.BLOCK);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcParticipationStepPersonalController.propTypes = {
  callback: PropTypes.func,
};

AcParticipationStepPersonalController.defaultProps = {
  callback: () => {},
};

export default AcParticipationStepPersonalController;
