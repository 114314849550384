// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

import AcTransaction from '@atoms/ac-transaction/ac-transaction.web';
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web';

const _CLASSES = {
  TRANSACTIONS: {
    MAIN: 'ac-transactions',
    EXPANDABLE: 'ac-transactions__expandable',
  },
};

const AcProjectTransactions = ({ project, section }) => {
  const groupTransactions =
    project &&
    project.transactions &&
    project.transactions.reduce((groups, transaction) => {
      const { year } = transaction;
      if (!groups[year]) {
        groups[year] = [];
      }

      groups[year].push(transaction);

      return groups;
    }, {});

  const groupTransactionArray = Object.keys(groupTransactions).map(year => {
    return {
      year,
      transactions: groupTransactions[year],
    };
  });

  const getTransactionsExpandableClassNames = () => {
    return clsx(_CLASSES.TRANSACTIONS.EXPANDABLE);
  };
  return (
    <AcSection
      theme={section.theme}
      key={section.id}
      id={`${section.rel}-${section.id}`}
      // className={getTransactionsClassNames()}
    >
      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            <AcHeading rank={4}>{section.label}</AcHeading>
          </AcColumn>
        </AcRow>
      </AcContainer>

      <AcContainer fluid={false}>
        <AcRow>
          <AcColumn>
            {groupTransactionArray
              .sort((a, b) => new Date(b.year) - new Date(a.year))
              .map((transaction, index) => (
                <AcExpandable
                  key={index}
                  title={transaction.year}
                  expanded={index === 0}
                  className={getTransactionsExpandableClassNames()}
                >
                  <AcTransaction
                    key={`ac-project-transaction-${transaction.year}`}
                    transaction={transaction.transactions}
                  />
                </AcExpandable>
              ))}
          </AcColumn>
        </AcRow>
      </AcContainer>

      {(!project.transactions || project.transactions.length === 0) && (
        <AcContainer fluid={false}>
          <AcRichContent
            content={'<p>Geen transacties beschikbaar</p>'}
            className={'h-text--color-medium h-text--italic'}
          />
        </AcContainer>
      )}
    </AcSection>
  );
};

export default withNamespaces()(React.memo(AcProjectTransactions));
