// Imports => React
import React, { useCallback, useEffect, useMemo } from 'react';
import { withNamespaces } from 'react-i18next';
// Imports => Controller

// Imports => Molecules
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web.js';
import AcAttachmentsList from '@molecules/ac-attachments-list/ac-attachments-list.web.js';
import clsx from 'clsx';

// Import => Components

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcIcon from '@atoms/ac-icon/ac-icon.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

// import AcKPI from '@atoms/ac-kpi/ac-kpi.web.js';

// Imports => Utils
import AcKPIGroup from '@molecules/ac-kpi-group/ac-kpi-group.web.js';
import AcSwiper from '@components/ac-swiper/ac-swiper.js';
import AcCurrencySymbol from '@utils/ac-currency-symbol';
import AcFormatDate from '@utils/ac-format-date';
import AcCapitalize from '@utils/ac-capitalize';

import { getAccessToken } from '../../config/index';
import axios from 'axios';
import AcAttachment from '../../atoms/ac-attachment/ac-attachment.web';
import { AcGetState } from '../../utilities/ac-storage';
const _CLASSES = {
  MAIN: 'ac-project-update',
  INTRODUCTION: 'ac-project-update__introduction',
  CAROUSEL: 'ac-project-update__carousel',
};

const baseUrl = process.env.API_URL;

// @inject('store')
// @observer
const AcProjectUpdate = props => {
  const {
    projectId,
    title,
    intro,
    content,
    expanded = false,
    attachments,
    kpis,
    published_at,
    region,
    name,
  } = props;

  const getCurrency = () => {
    if (region && region === 'USA') return AcCurrencySymbol('USD');
    return AcCurrencySymbol('EUR');
  };

  const carouselItems = [
    ...attachments.images.map(n => ({ ...n, type: 'image' })),
    ...attachments.videos
      .filter(({ position }) => position === 'both' || position === 'carousel')
      .map(n => ({ ...n, type: 'video' })),
    ,
  ];

  const getFormattedDate = ({ hasSuffix }) => {
    const result = AcFormatDate(published_at, 'DD-MM-YYYY', 'MMMM YYYY');

    return AcCapitalize(`${result} ${hasSuffix ? 'update' : ''}`);
  };

  const getIntroductionClassNames = () => {
    return clsx(_CLASSES.INTRODUCTION);
  };

  const getStyleClassNames = () => {
    return clsx(_CLASSES.MAIN);
  };

  const downloadPdf = useCallback(() => {
    const _downloadPdf = id => {
      axios
        .get(`${baseUrl}/project-update/download/${id}`, {
          headers: { Authorization: 'Bearer ' + AcGetState('access_token') },
        })
        .then(response => {
          const bufferArray = base64ToArrayBuffer(response.data);
          const blobStore = new Blob([bufferArray], {
            type: 'application/pdf',
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobStore);
            return;
          }
          const data = window.URL.createObjectURL(blobStore);

          var link = document.createElement('a');
          document.body.appendChild(link);
          link.href = data;
          link.download = `${name}_${title}.pdf`;
          link.click();
          window.URL.revokeObjectURL(data);
          link.remove();

          function base64ToArrayBuffer(data) {
            var bString = window.atob(data);
            var bLength = bString.length;
            var bytes = new Uint8Array(bLength);
            for (var i = 0; i < bLength; i++) {
              var ascii = bString.charCodeAt(i);
              bytes[i] = ascii;
            }
            return bytes;
          }
        })
        .catch(err => {
          console.error('Error during downloading pdf...', err);
        });
    };
    _downloadPdf(projectId);
  }, [projectId, name, title]);

  return (
    <div className="ac-project-update">
      <AcExpandable
        title={name || getFormattedDate({ hasSuffix: false })}
        className={getStyleClassNames()}
        expanded={expanded}
      >
        {kpis && (
          <div className="h-margin-bottom-20">
            <AcKPIGroup
              autoAlign={kpis.length <= 3}
              currency={getCurrency()}
              kpis={kpis}
              class="project-update"
            />
          </div>
        )}

        <AcRow>
          <AcColumn>
            <AcHeading rank={7} theme={'medium'}>
              {published_at}
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12} sm={12} md={8}>
            {title && <AcHeading rank={4}>{title}</AcHeading>}
            {intro && (
              <AcRichContent
                content={intro}
                className={getIntroductionClassNames()}
              />
            )}
            {content && <AcRichContent content={content} />}
          </AcColumn>
          <AcColumn xs={12} sm={12} md={{ size: 3, offset: 1 }}>
            {attachments && attachments.files.length !== 0 && (
              <AcAttachmentsList
                attachments={[].concat(
                  ...attachments.files,
                  ...attachments.videos.filter(
                    ({ position }) =>
                      position === 'sidebar' || position === 'both'
                  )
                )}
              />
            )}

            {(!attachments || attachments.length === 0) && (
              <AcRichContent
                content={'<p>Geen bijlagen beschikbaar</p>'}
                className={'h-text--color-medium h-text--italic'}
              />
            )}
          </AcColumn>
        </AcRow>
        {carouselItems.length ? <AcSwiper items={carouselItems} wrap /> : ''}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <AcButton
            theme={'download'}
            style={{ alignSelf: 'end', minWidth: '2rem', maxWidth: '2rem' }}
            callback={downloadPdf}
          >
            <AcIcon icon={'download'} className={'ac-button__icon'} />
            <AcRipple size={'small'} />
          </AcButton>
        </div>
      </AcExpandable>
    </div>
  );
};

export default withNamespaces()(AcProjectUpdate);
