// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

// Imports => Controller
import AcPrivateRouteController from './ac-private-route.controller.js';

// PrivateRoute
const PrivateRoute = ({
  authorized,
  forbidden,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return !forbidden || (forbidden && authorized) ? (
          <Component {...props} />
        ) : props.location.pathname === window.location.pathname ? (
          <Redirect
            from={props.location.pathname}
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : null;
      }}
    />
  );
};

@inject('store')
@observer
class AcPrivateRoute extends AcPrivateRouteController {
  render() {
    const { store, forbidden, component, ...rest } = this.props;

    return (
      <PrivateRoute
        authorized={store.auth.isAuthorized}
        forbidden={forbidden}
        component={component}
        {...rest}
      />
    );
  }
}

export default AcPrivateRoute;
