// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcProjectIntroductionController from './ac-project-introduction.controller.js';

// Imports => Atoms
import { AcContainer } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcImage from '@atoms/ac-image/ac-image.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';

@inject('routing')
@observer
class AcProjectIntroduction extends AcProjectIntroductionController {
  render() {
    const { image, title, location, callback } = this.props;

    return (
      <div className={this.getStyleClassNames()} ref={node => (this.el = node)}>
        <div
          className={this.getBackgroundWrapClassNames()}
          ref={node => (this.background.wrp = node)}
        >
          {image && image.responsive && image.responsive.xl && (
            <AcImage
              source={image.responsive.xl}
              alt={image.label}
              className={this.getBackgroundClassNames()}
              callback={this.handleBlazyCallback}
              parallax={true}
              id={'ac-hero-image'}
            />
          )}
        </div>

        <div className={this.getOverlayClassNames()} />

        <div className={this.getInnerClassNames()}>
          <AcContainer fluid={true} className={this.getContainerClassNames()}>
            <AcHeading rank={1} className={this.getTitleClassNames()}>
              {title}
            </AcHeading>
            <AcHeading rank={2} className={this.getSubTitleClassNames()}>
              {location}
            </AcHeading>
          </AcContainer>
          <AcContainer fluid={true} className={this.getContainerClassNames()}>
            <AcButton
              theme="alpha"
              variant="outline"
              className={this.getButtonClassNames()}
              {...{ callback }}
            >
              investment memorandam
            </AcButton>
          </AcContainer>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(AcProjectIntroduction);
