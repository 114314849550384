// Imports => React
import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';

import AcImage from '@atoms/ac-image/ac-image.web';
import AcAttributes from '@atoms/ac-attributes/ac-attributes.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';

import AcButton from '@atoms/ac-button/ac-button.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcNectarSlider from '@components/ac-nectar-slider/ac-nectar-slider.web.js';

const _CLASSES = {
  MAIN: 'ac-project-card',
  ORIENTATION: {
    HORIZONTAL: 'ac-project-card--horizontal',
    VERTICAL: 'ac-project-card--vertical',
  },
  IMAGE: 'ac-project-card__image',
  ARTICLE: {
    MAIN: 'ac-project-card__article',
    HEADER: 'ac-project-card__article__header',
    BODY: 'ac-project-card__article__body',
    FOOTER: 'ac-project-card__article__footer',
    TITLE: 'ac-project-card__article__title',
    SUBTITLE: 'ac-project-card__article__subtitle',
  },
};

const _COLUMNSIZES = {
  HORIZONTAL: {
    SM: {
      FIRST: 5,
      SECOND: 7,
    },
    MD: 6,
  },
  VERTICAL: 12,
};

const AcProjectCard = ({
  orientation = 'horizontal',
  className,
  project,
  project: {
    id,
    cover,
    name,
    attributes,
    completedAttributes,
    project_featured_images,
    actions,
    city,
    state,
    country,
  },
  history: { push },
}) => {
  const handleCallback = useCallback((event, el) => {
    if (id && push) push(`/project/${id}`);
  }, []);

  const getLocation = useMemo(() => {
    let result = [];

    if (city && city !== null && city !== '') result.push(city);
    if (state && state !== null && state !== '') result.push(state);
    if (country && country !== null && country.name !== '')
      result.push(country.name);

    return result.join(', ');
  }, [city, state, country]);

  const getColumnSize = order => {
    const orient = orientation.toUpperCase();

    if (orient === 'HORIZONTAL') {
      return {
        sm:
          order === 'first'
            ? _COLUMNSIZES.HORIZONTAL.SM.FIRST
            : _COLUMNSIZES.HORIZONTAL.SM.SECOND,
        md: _COLUMNSIZES.HORIZONTAL.MD,
      };
    } else {
      return {
        sm: _COLUMNSIZES.VERTICAL,
        md: _COLUMNSIZES.VERTICAL,
      };
    }
  };

  const getSubtitleClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.SUBTITLE);
  }, []);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.TITLE);
  }, []);

  const getFooterClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.FOOTER);
  }, []);

  const getBodyClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.BODY);
  }, []);

  const getHeaderClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.HEADER);
  }, []);

  const getArticleClassNames = useMemo(() => {
    return clsx(_CLASSES.ARTICLE.MAIN);
  }, []);

  const getImageClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      orientation && _CLASSES.ORIENTATION[orientation.toUpperCase()],
      className
    );
  }, [orientation, className]);

  const renderCoverImage = useMemo(() => {
    if (project_featured_images && project_featured_images.length > 0) {
      return <AcNectarSlider slides={project_featured_images} />;
    } else if (cover && cover.responsive && cover.responsive.md) {
      return (
        <AcImage
          type={'background'}
          source={cover.responsive.md}
          alt={cover.label}
          wrpClassName={getImageClassNames}
        />
      );
    }
  }, [cover, getImageClassNames, project, project_featured_images]);

  return (
    <AcCard className={getStyleClassNames} id={id}>
      <AcRow noGutters={true}>
        <AcColumn xs={12} {...getColumnSize('first')}>
          {renderCoverImage}
        </AcColumn>
        <AcColumn xs={12} {...getColumnSize('second')}>
          <article className={getArticleClassNames}>
            <div className={getHeaderClassNames}>
              {getLocation && getLocation !== '' && (
                <AcHeading
                  rank={7}
                  theme={'alpha'}
                  className={getSubtitleClassNames}
                >
                  {getLocation}
                </AcHeading>
              )}
              <AcHeading
                rank={4}
                variant={'uppercase'}
                className={getTitleClassNames}
              >
                {name && name}
              </AcHeading>
            </div>

            <div className={getBodyClassNames}>
              {attributes && (
                <AcAttributes
                  attributes={
                    orientation === 'vertical'
                      ? attributes
                      : completedAttributes
                  }
                />
              )}
            </div>

            <div className={getFooterClassNames}>
              <AcButton
                theme={'alpha'}
                variant={'outline'}
                callback={handleCallback}
              >
                Details
                <AcRipple theme={'alpha'} size={'small'} />
              </AcButton>
            </div>
          </article>
        </AcColumn>
      </AcRow>
    </AcCard>
  );
};

export default withRouter(React.memo(AcProjectCard));
